import { interval, Subject } from "rxjs";
import { writable } from "svelte/store";
import type { DialogResult, IDialogConfig } from "../models";
import { Deferred } from "../utils/deferred-promise";
import { initSessionStore } from "./session-store";


export let appInitialized = false;
export const initializeApp = () => {
    initSessionStore();
    appInitialized = true;
}



export const logDebugData = writable<{ time: string, msg: string }[]>([]);
export const logDebug = (msg: string) => {
    const time = (new Date()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    logDebugData.update(lst => ([{ time, msg }, ...lst.slice(0, 99)]));
}

export const appPageStore = writable<"connect" | "connecting" | "session">("connect");


// This section is to manage the app-confirm-dialog-wrapper to have a global confirmdialog component
// That we can await from everywhere
export const appConfirmDialogStoreOpen = writable<IDialogConfig>(undefined);
export let appConfirmDialogStoreClosePromise: Deferred<DialogResult>;
export const showAppConfirmDialogAsync = async (config: IDialogConfig): Promise<DialogResult> => {
    appConfirmDialogStoreOpen.set(config);
    appConfirmDialogStoreClosePromise = new Deferred<DialogResult>();
    return appConfirmDialogStoreClosePromise;
}

export const showAppMessageAsync = async (title: string, text: string, textIsHtml = false): Promise<DialogResult> => {
    appConfirmDialogStoreOpen.set({
        title: title,
        contentText: text,
        showOk: true,
        showX: true,
        showCancel: false,
        showClose: false,
        contentPadding: '2rem',
        contentTextIsHtml: textIsHtml
    });
    appConfirmDialogStoreClosePromise = new Deferred<DialogResult>();
    return appConfirmDialogStoreClosePromise;
}

export const resetApplication = () => {
    window.location.replace(window.location.origin);
};

export const appClickedSubject = new Subject<void>;

export let lastClick = Date.now();
appClickedSubject.subscribe(() => {
    lastClick = Date.now();
})

interval(60000).subscribe(() => {
    const idleTime = Date.now() - lastClick;
    if (idleTime >= import.meta.env.VITE_SOLOHUB_INACTIVE_TIMEOUT_MINUTES * 60000) {
        resetApplication();
    }
})