<script lang="ts">
    import Panel from "./components/panel.svelte";
    import SinglePlayer from "./components/single-player.svelte";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SingleSession">
    <div class="panel">
        <Panel title="MUSIQUE COURANTE">
            <SinglePlayer />
        </Panel>
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SingleSession"] {
        .panel {
            box-shadow: 0px 0px 15px #aaaaaa;
            border-radius: 5px;
            overflow: hidden;
        }
    }
</style>
