<script lang="ts">
  import { onMount } from "svelte";
  import { get } from "svelte/store";
  import Checkbox from "../lib/components/checkbox.svelte";
  import NavigateFolderSong from "../lib/components/navigate-folder-song.svelte";
  import { SessionType, type IFolder, type ISong } from "../models";
  import type { SongSelectionResult } from "../models/song-selection-result";
  import { showAppMessageAsync } from "../stores/app-store";
  import { libraryStore, playerInfoStore, queueStore, sessionDataStore, sessionInfoStore } from "../stores/session-store";

  import Modal from "./modal.svelte";

  export let currentFolder: IFolder = undefined;
  export let enableCoachRequest: boolean = true;

  let queuedSongIds = [];
  onMount(() => {});

  let modal: Modal;

  let selectedSong: ISong;
  let showCoachRequestBox = enableCoachRequest;
  let isCoachRequest: boolean = false;
  let overrideSpecificPosition: number;
  let forcePositionSelected: boolean = false;

  export const openDialogAsync = async (): Promise<SongSelectionResult> => {
    initDialog();
    await modal.openDialogAsync();
    if (!selectedSong) {
      return undefined;
    }
    return {
      songId: selectedSong.id,
      isCoachRequest,
      overrideSpecificPosition: isCoachRequest && forcePositionSelected && overrideSpecificPosition ? overrideSpecificPosition : undefined,
    };
  };

  const initDialog = () => {
    showCoachRequestBox = enableCoachRequest && $sessionInfoStore.isCoach;
    currentFolder = get(libraryStore).root;

    queuedSongIds = get(sessionDataStore).sessionType === SessionType.group ? get(queueStore).map((qs) => qs.id) : [];

    const currentSong = get(playerInfoStore)?.song;
    if (currentSong) {
      queuedSongIds.push(currentSong);
    }

    selectedSong = undefined;
    isCoachRequest = false;
    forcePositionSelected = false;
    overrideSpecificPosition = 1;
  };

  const songSelected = async (song: ISong) => {
    if (queuedSongIds.indexOf(song.id) >= 0) {
      await showAppMessageAsync("Ajout impossible", "Cette musique est déjà dans la liste");
      return;
    }

    selectedSong = song;
    modal.closeDialog();
  };
</script>

<!-- COMPONENT -->
<Modal
  bind:this={modal}
  config={{
    title: "Sélection de musique",
    showCancel: true,
    showX: true,
    dialogHeight: "80vh",
    autoSize: false,
    headerIcon: "fa-solid fa-list-ol",
    width: "min(90vw, 500px)",
  }}
>
  <div data-component="SelectSongDialog">
    {#if showCoachRequestBox}
      <div class="top">
        <div class="coach-checkbox">
          <Checkbox bind:checked={isCoachRequest}>
            <div>
              <i class="fa-solid fa-user-group" />
              <span>Priorité entraîneur</span>
            </div>
          </Checkbox>
        </div>

        {#if isCoachRequest}
          <div class="override-position">
            <Checkbox bind:checked={forcePositionSelected}>
              <div>
                <i class="fa-solid fa-arrow-up-1-9" />
                <span>Forcer une position</span>
              </div>
            </Checkbox>
            {#if forcePositionSelected}
              <input type="number" bind:value={overrideSpecificPosition} />
            {/if}
          </div>
        {/if}
      </div>
    {/if}
    <div class="content">
      <NavigateFolderSong bind:currentFolder on:songSelected={(ev) => songSelected(ev.detail)} disabledIds={queuedSongIds} />
    </div>
  </div>
</Modal>

<!-- STYLE -->
<style lang="scss">
  [data-component="SelectSongDialog"] {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      flex: auto;
      overflow: hidden;
    }
    .top {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      border: 1px solid lightgray;
      padding: 1rem;
      margin-bottom: 1rem;
      font-size: 1.2rem;

      i {
        font-size: 1.5rem;
      }

      .override-position {
        display: flex;
        align-items: center;
        gap: 1rem;
        input {
          text-align: center;
          width: 100px;
        }
      }
    }
  }
</style>
