<script lang="ts">
    import { differenceInMinutes } from "date-fns";
    import { generate } from "short-uuid";
    import { onMount } from "svelte";
    import { appPageStore } from "../stores/app-store";
    import { sessionInfoStore } from "../stores/session-store";
    import { appLocalStorage } from "../utils/app-local-storage";
    import Checkbox from "./components/checkbox.svelte";

    const THRESHOLD_AUTO_RECONNECT_SESSION = 120; //120 MINUTES

    let inputSessionId: string;
    let inputUsername: string;
    let inputIsCoach: boolean;

    onMount(() => {
        if (!appLocalStorage.deviceUserId) {
            appLocalStorage.deviceUserId = generate();
        }

        const urlParams = new URLSearchParams(window.location.search);

        const noAutoConnect = urlParams.get("noAutoConnect") === "1";
        if (noAutoConnect) {
            urlParams.delete("noAutoConnect");
            let newUrl = location.href.split("?")[0] + "?" + urlParams.toString();
            history.replaceState({}, "", newUrl);
        }

        const sessionIdParam = urlParams.get("sId");
        if (sessionIdParam) {
            inputSessionId = sessionIdParam;
        }

        let lastSessionInfo = appLocalStorage.sessionInfo;
        if (lastSessionInfo) {
            inputUsername = lastSessionInfo.username;
            inputIsCoach = lastSessionInfo.isCoach;

            // Auto reconnect if same sessionId and connected date is not too old
            if (!noAutoConnect && sessionIdParam && lastSessionInfo.sessionId === sessionIdParam && lastSessionInfo.connectedDate) {
                const lastSeenInMinutes = differenceInMinutes(new Date(), new Date(lastSessionInfo.connectedDate || new Date()));
                if (lastSeenInMinutes < THRESHOLD_AUTO_RECONNECT_SESSION) {
                    connect();
                }
            }
        }
    });

    const connect = async () => {
        sessionInfoStore.set({
            sessionId: inputSessionId,
            username: inputUsername,
            isCoach: inputIsCoach,
            connectedDate: new Date(),
        });
        appPageStore.set("connecting");
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="ConnectPage">
    <div class="container">
        <div class="session-id-block">
            <h3>No de session</h3>

            <input class="session-id-input" maxlength="5" bind:value={inputSessionId} />

            <span class="smaller-label"> Sensible aux majuscules et minuscules </span>
        </div>

        <div class="username-block">
            <h3>Entrez votre nom</h3>
            <input bind:value={inputUsername} maxlength="15" />
        </div>
        <div class="is-coach-block">
            <Checkbox bind:checked={inputIsCoach}>Je suis un entraîneur</Checkbox>
            {#if inputIsCoach}
                <div class="confirm-text">
                    En me connectant, je confirme être un entraîneur qui a les droits de priorité lors des demandes d'ajout de musique à la liste
                </div>
            {/if}
        </div>

        <button class="connect-button" disabled={!inputUsername || inputSessionId?.length !== 5} on:click={() => connect()}>
            <i class="fa-solid fa-satellite-dish" />
            <span>Connecter à la session</span>
        </button>
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="ConnectPage"] {
        width: 100%;

        .container {
            max-width: min(20rem, 95vw);
            margin: 0 auto;

            display: flex;
            flex-direction: column;
            gap: 2.5rem;

            h3 {
                color: $primary;
                margin: 0;
            }

            .session-id-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;

                .session-id-input {
                    max-width: 6rem;
                }
            }

            .username-block {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;
            }

            .is-coach-block {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.5rem;

                .confirm-text {
                    background-color: lightblue;
                    padding: 1rem;
                    border-radius: 0.5rem;
                }
            }

            .smaller-label {
                font-size: 0.7rem;
            }

            input {
                text-align: center;
            }

            .connect-button {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                padding: 2rem;
            }
        }
    }
</style>
