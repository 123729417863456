<script lang="ts">
  import type { DialogResult } from "../models/enums/DialogResult";
  import type { IDialogConfig } from "../models/IDialogConfig";
  import Modal from "./modal.svelte";

  let defaultDialogConfig: IDialogConfig = {
    title: "",
    showCancel: false,
    showOk: false,
    showClose: false,
    showX: false,
    autoSize: true,
  };
  let dialogConfig: IDialogConfig = { ...defaultDialogConfig };
  let modal: Modal;

  export const openDialogAsync = async (config: IDialogConfig): Promise<DialogResult> => {
    dialogConfig = { ...defaultDialogConfig, ...config };
    return modal.openDialogAsync();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal bind:this={modal} config={dialogConfig}>
  <div data-component="ConfirmDialog">
    <div class="content-container">
      {#each dialogConfig.contentText as line}
        {#if dialogConfig.contentTextIsHtml}
          {@html line}
        {:else}
          {line}
        {/if}
      {/each}
    </div>
  </div>
</Modal>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="ConfirmDialog"] {
    .content-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
</style>
