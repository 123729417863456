<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import type { ISong } from "../../models";
    import { millisecondsToTime } from "../../utils/general";

    export let song: ISong;
    export let clickable: boolean = false;

    const dispatch = createEventDispatcher();
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div
    data-component="SongBox"
    on:click={() => dispatch("click")}
    class:clickable
>
    <i class="fa-solid fa-music song-icon" />

    <div class="song-identifier">
        <div class="song-text">{song.name}</div>
        <div class="song-length">
            ({millisecondsToTime(song.duration)})
        </div>
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SongBox"] {
        display: flex;
        gap: 1rem;
        align-items: center;
        background-color: $song-color;
        border: 1px solid $secondary;
        border-radius: 5px;
        color: black;
        padding: 0 1rem;
        height: 3rem;

        .song-icon {
            font-size: 1.5rem;
        }

        .song-identifier {
            display: flex;
            flex: auto;
            align-items: center;
            gap: 0.5rem;
            overflow: hidden;

            .song-text {
                font-weight: bold;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .song-length {
                font-size: 0.8rem;
                display: flex;
                flex-wrap: nowrap;
            }
        }
    }
</style>
