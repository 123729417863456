<script lang="ts">
    import { ClientMessageTypes, DialogResult, PlayerActionTypes } from "../../models/enums";
    import { showAppConfirmDialogAsync } from "../../stores/app-store";
    import { playerInfoStore, sessionDataStore } from "../../stores/session-store";
    import { sendToHostAsync } from "../../stores/signalr-store";
    import { appLocalStorage } from "../../utils/app-local-storage";
    import AudioProgress from "./audio-progress.svelte";
    import PlayerSongInfo from "./player-song-info.svelte";

    let canManage = false;

    $: canManage = $playerInfoStore?.requestedBy?.deviceUserId === appLocalStorage.deviceUserId || $sessionDataStore.sessionType === "single";

    const timeUpdated = (eventData: CustomEvent<number>) => {
        sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.UpdatePlayTime, time: eventData.detail });
    };

    const nextSong = async () => {
        const res = await showAppConfirmDialogAsync({
            title: "Sauter musique",
            contentText: "Sauter à la prochaine musique de la liste?",
            showOk: true,
            showCancel: true,
        });
        if (res !== DialogResult.ok) {
            return;
        }
        sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.SkipCurrent });
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="player">
    {#if $playerInfoStore}
        <div class="song-info">
            <PlayerSongInfo song={$playerInfoStore.song} />

            <div class="song-status">
                <div
                    class="player-progress"
                    class:hide={$playerInfoStore.status !== "paused" && $playerInfoStore.status !== "playing"}
                    class:ghost={$playerInfoStore.status === "paused" && !canManage}
                >
                    <AudioProgress playerInfo={$playerInfoStore} allowDrag={canManage} on:timeUpdated={timeUpdated} />
                </div>

                {#if $playerInfoStore.status === "callout"}
                    <div class="player-status">
                        <i class="fa-solid fa-comment-dots" />
                        <div>Annonce</div>
                    </div>
                {/if}

                {#if $playerInfoStore.status === "downloading"}
                    <div class="player-status">
                        <i class="fa-solid fa-cloud-arrow-down" />
                        <div>Téléchargement</div>
                    </div>
                {/if}

                {#if $playerInfoStore.status === "paused" && !canManage}
                    <div class="player-status">
                        <i class="fa-solid fa-pause" />
                        <div>Pause</div>
                    </div>
                {/if}
            </div>

            {#if canManage}
                <div class="controls">
                    <div class="button-section">
                        <button
                            class="icon-md"
                            on:click={() => sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.ReplayCallout })}
                        >
                            <i class="fa-solid fa-backward-step" />
                            <i class="fa-regular fa-comment-dots" />
                        </button>
                        {#if $playerInfoStore.status === "callout"}
                            <button
                                class="icon-md"
                                on:click={() => sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.SkipCallout })}
                            >
                                <i class="fa-regular fa-comment-dots" />
                                <i class="fa-solid fa-xmark" />
                            </button>
                        {/if}
                    </div>
                    <div class="button-section">
                        {#if $playerInfoStore.status === "playing"}
                            <button
                                class="icon-lg"
                                on:click={() => sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.Pause })}
                            >
                                <i class="fa-solid fa-pause" />
                            </button>
                        {/if}
                        {#if $playerInfoStore.status === "paused"}
                            <button
                                class="icon-lg"
                                on:click={() => sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.Play })}
                            >
                                <i class="fa-solid fa-play" />
                            </button>
                        {/if}
                    </div>
                    <div class="button-section">
                        <button class="icon-md next-button" on:click={() => nextSong()}>
                            <i class="fa-solid fa-forward-step" />
                        </button>
                    </div>
                </div>
            {/if}
        </div>
    {:else}
        <div class="no-current-song gray-message">Aucune musique en cours</div>
    {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="player"] {
        .song-info {
            background-color: $song-color;
            border: 1px solid $secondary;
            border-radius: 5px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .song-status {
                display: grid;

                .player-progress {
                    grid-row: 1;
                    grid-column: 1;

                    &.ghost {
                        opacity: 0.4;
                    }
                }

                .player-status {
                    grid-row: 1;
                    grid-column: 1;
                    z-index: 10;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                    gap: 1rem;
                    font-size: 2rem;

                    color: $bold;
                    i {
                        font-size: 2rem;
                    }
                }
            }

            .song-name {
                display: flex;
                font-size: 1.5rem;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }

            .controls {
                display: flex;
                justify-content: space-between;

                .button-section {
                    display: flex;
                    gap: 1rem;
                }
            }
        }

        .no-current-song {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
