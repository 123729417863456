import { get, writable } from "svelte/store";
import type { IFolder, ILibrary, ISong } from "../models";
import type { IGroupPlayerInfoDTO, IGroupSessionDataDTO, ILibraryDTO, IQueuedSongDTO, ISingleSessionDataDTO } from "../models/DTO";
import type { ISinglePlayerInfoDTO } from "../models/DTO/ISinglePlayerInfoDTO";
import type { IPlayerInfo } from "../models/IPlayerInfo";
import type { ISessionInfo } from "../models/ISessionInfo";
import { QueuedSong } from "../models/QueuedSong";
import { appLocalStorage } from "../utils/app-local-storage";
import { appInitialized } from "./app-store";

export const sessionDataStore = writable<IGroupSessionDataDTO | ISingleSessionDataDTO>();
export const sessionInfoStore = writable<ISessionInfo>();

export const libraryStore = writable<ILibrary>();
export const queueStore = writable<QueuedSong[]>();
export const playerInfoStore = writable<IPlayerInfo>();


export const initSessionStore = () => {
    // Adds sessionId to querystring when it changes
    sessionInfoStore.subscribe(sessionInfo => {
        if (!appInitialized) {
            return;
        }

        appLocalStorage.sessionInfo = sessionInfo;

        if (sessionInfo?.sessionId) {
            history.pushState({}, '', `?sId=${sessionInfo.sessionId}`);
        } else {
            history.pushState({}, '', '');
        }
    })

}


export const updateLibraryStore = (dto: ILibraryDTO) => {

    if (!dto) {
        libraryStore.set(undefined);
        return;
    }

    const allFolders: IFolder[] = dto.folders.map(f => ({ ...f }));
    const allSongs: ISong[] = dto.songs.map(s => ({ ...s }));

    allFolders.forEach(f => {
        f.folders = allFolders.filter(childFolder => childFolder.parentId === f.id)
        f.songs = allSongs.filter(song => song.parentId === f.id)
    })

    const lib: ILibrary = {
        foldersFlat: allFolders,
        songsFlat: allSongs,
        root: allFolders.find(f => !f.parentId)
    }

    libraryStore.set(lib);
}

export const updateQueueStore = (dto: IQueuedSongDTO[]) => {
    if (!dto) {
        queueStore.set([])
        return;
    }
    const queue = dto.map(itm => new QueuedSong(itm))
    queueStore.set(queue);
}

export const updateGroupPlayer = (dto: IGroupPlayerInfoDTO) => {
    if (!dto) {
        playerInfoStore.set(undefined);
        return;
    }

    const playerInfo: IPlayerInfo = {
        status: dto.status,
        time: dto.time,
        song: new QueuedSong(dto.song),
        requestedBy: dto.song.requestedBy
    }
    playerInfoStore.set(playerInfo);
}

export const updateSinglePlayer = (dto: ISinglePlayerInfoDTO) => {
    if (!dto) {
        playerInfoStore.set(undefined);
        return;
    }

    if (!get(libraryStore)) {
        return;
    }

    const playerInfo: IPlayerInfo = {
        status: dto.status,
        time: dto.time,
        song: get(libraryStore).songsFlat.find(s => s.id === dto.songId),
        requestedBy: dto.requestedBy,
        snapTime: dto.snapTime
    }
    playerInfoStore.set(playerInfo);
}