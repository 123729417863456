<script lang="ts">
    import { fade, fly, scale } from "svelte/transition";
    import SelectSongDialog from "../../dialogs/select-song-dialog.svelte";
    import { DialogResult } from "../../models";
    import { showAppConfirmDialogAsync } from "../../stores/app-store";
    import { queueStore } from "../../stores/session-store";
    import { requestAddToQueue, requestRemoveFromQueue } from "../../stores/signalr-store";
    import { appLocalStorage } from "../../utils/app-local-storage";
    import Panel from "./panel.svelte";
    import QueuedSongBox from "./queued-song-box.svelte";

    let selectSongDialog: SelectSongDialog;
    const removeFromQueue = async (qId: string) => {
        const res = await showAppConfirmDialogAsync({
            title: "Retirer de la liste",
            contentText: "Cette musique sera retirée de la liste",
            showOk: true,
            showCancel: true,
            showX: true,
        });
        if (res !== DialogResult.ok) {
            return;
        }
        requestRemoveFromQueue(qId);
    };

    const addToQueue = async () => {
        const res = await selectSongDialog.openDialogAsync();
        if (!res) {
            return;
        }
        requestAddToQueue(res);
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="queue">
    <Panel title="LISTE D'ATTENTE">
        <div class="header">
            <button class="add-button" on:click={() => addToQueue()}>
                <div class="add-button-content">
                    <i class="fa-solid fa-circle-plus" />
                    <span>Ajouter</span>
                </div>
            </button>
        </div>

        {#if $queueStore.length <= 0}
            <div class="empty-list gray-message">Aucune musique en attente</div>
        {/if}
        <div class="list-container" transition:scale>
            {#each $queueStore as qs (qs.qId)}
                <div in:fly={{ y: -20, duration: 500 }} out:fade>
                    <QueuedSongBox
                        song={qs}
                        showClose={qs.requestedBy?.deviceUserId === appLocalStorage.deviceUserId}
                        on:removeFromQueue={(ev) => {
                            removeFromQueue(ev.detail);
                        }}
                    />
                </div>
            {/each}
        </div>
    </Panel>
</div>

<SelectSongDialog bind:this={selectSongDialog} />

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="queue"] {
        .header {
            display: flex;
            justify-content: center;
            margin-bottom: 1rem;
        }

        .add-button {
            width: 12rem;
        }

        .list-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .empty-list {
            text-align: center;
            min-height: 3rem;
        }
    }
</style>
