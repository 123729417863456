<script lang="ts">
    import { sessionConnectionStatusStore } from "../../stores/signalr-store";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SignalrConnectionVisual">
    <i class="fa-solid fa-mobile-screen-button" />
    <div
        class="bar"
        class:connecting={$sessionConnectionStatusStore.signalrConnection === "connecting"}
        class:connected={$sessionConnectionStatusStore.signalrConnection === "connected"}
        class:error={$sessionConnectionStatusStore.signalrConnection === "error"}
    />
    <i class="fa-solid fa-cloud" />
    <div
        class="bar"
        class:connecting={$sessionConnectionStatusStore.hostConnection === "connecting"}
        class:connected={$sessionConnectionStatusStore.hostConnection === "connected"}
        class:error={$sessionConnectionStatusStore.hostConnection === "error"}
    />
    <i class="fa-solid fa-tablet-screen-button" />
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SignalrConnectionVisual"] {
        display: flex;
        align-items: center;
        width: 300px;

        .bar {
            height: 0.5rem;
            flex: auto;
            background-color: lightgray;
            margin: 0 1rem;
            border-radius: 1rem;

            &.connecting {
                display: inline-block;
                background-color: #fff;
                border: 1px solid #fff;

                background-image: linear-gradient(
                    45deg,
                    rgba(0, 0, 0, 0.25) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(0, 0, 0, 0.25) 50%,
                    rgba(0, 0, 0, 0.25) 75%,
                    transparent 75%,
                    transparent
                );
                font-size: 30px;
                background-size: 1em 1em;
                box-sizing: border-box;
                animation: barStripe 1s linear infinite;

                @keyframes barStripe {
                    0% {
                        background-position: -1em 0;
                    }
                    100% {
                        background-position: 0 0;
                    }
                }
            }

            &.connected {
                background-color: darkgreen;
            }

            &.error {
                background-color: darkred;
            }
        }

        .success {
            color: $primary;
        }

        .inprogress {
            color: $primary;
        }

        .inactive {
            color: gray;
        }

        .failure {
            color: darkred;
        }
    }
</style>
