<script lang="ts">
    import { get } from "svelte/store";
    import SelectSongDialog from "../../dialogs/select-song-dialog.svelte";
    import { ClientMessageTypes, PlayerActionTypes } from "../../models/enums";
    import { playerInfoStore, sessionDataStore } from "../../stores/session-store";
    import { sendToHostAsync } from "../../stores/signalr-store";
    import { appLocalStorage } from "../../utils/app-local-storage";
    import AudioProgress from "./audio-progress.svelte";
    import PlayerSongInfo from "./player-song-info.svelte";

    let currentPlayerTime: number;
    let selectSongDialog: SelectSongDialog;

    let canManage = false;

    $: canManage = $playerInfoStore?.requestedBy?.deviceUserId === appLocalStorage.deviceUserId || $sessionDataStore.sessionType === "single";

    const timeUpdated = (eventData: CustomEvent<number>) => {
        sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.UpdatePlayTime, time: eventData.detail });
    };

    const selectSong = async () => {
        const song = await selectSongDialog.openDialogAsync();
        sendToHostAsync(ClientMessageTypes.LoadSingleSessionSong, song.songId);
    };

    const addSnap = async () => {
        sendToHostAsync(ClientMessageTypes.AddSnap, currentPlayerTime);
    };

    const gotoSnap = () => {
        sendToHostAsync(ClientMessageTypes.PlayerAction, {
            action: PlayerActionTypes.UpdatePlayTime,
            time: get(playerInfoStore).snapTime,
        });
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="player">
    {#if $playerInfoStore}
        <div class="song-info">
            {#if $playerInfoStore.song}
                <PlayerSongInfo song={$playerInfoStore.song} />

                <div class="song-status">
                    <div
                        class="player-progress"
                        class:hide={$playerInfoStore.status !== "paused" && $playerInfoStore.status !== "playing"}
                        class:ghost={$playerInfoStore.status === "paused" && !canManage}
                    >
                        <AudioProgress
                            playerInfo={$playerInfoStore}
                            allowDrag={canManage}
                            on:timeUpdated={timeUpdated}
                            bind:time={currentPlayerTime}
                        />
                    </div>

                    {#if $playerInfoStore.status === "downloading"}
                        <div class="player-status">
                            <i class="fa-solid fa-cloud-arrow-down" />
                            <div>Téléchargement</div>
                        </div>
                    {/if}

                    {#if $playerInfoStore.status === "paused" && !canManage}
                        <div class="player-status">
                            <i class="fa-solid fa-pause" />
                            <div>Pause</div>
                        </div>
                    {/if}
                </div>
            {:else}
                <div id="nosong" class="song-box">
                    <div class="choose-music">
                        <span class="empty gray-message"> Choisissez une musique </span>
                        <button class="icon-lg load-song-button" on:click={() => selectSong()}>
                            <i class="fa-solid fa-music" />
                            <i class="fa-solid fa-ellipsis" />
                        </button>
                    </div>
                </div>
            {/if}
        </div>

        <div class="controls">
            <div class="button-section">
                <button
                    class="icon-sm"
                    on:click={() => sendToHostAsync(ClientMessageTypes.PlayerAction, { action: PlayerActionTypes.UpdatePlayTime, time: 0 })}
                    disabled={!$playerInfoStore.song}
                >
                    <i class="fa-solid fa-backward-step" />
                </button>
                {#if $playerInfoStore.snapTime}
                    <button class="icon-md goto-snap-button" disabled={!$playerInfoStore.song} on:click={() => gotoSnap()}>
                        <i class="fa-solid fa-caret-left arrow" />
                        <i class="fa-solid fa-location-pin" />
                        <i class="fa-solid fa-caret-right arrow" />
                    </button>
                {/if}
            </div>
            <div class="button-section" />
            <div class="button-section">
                <button
                    class="play-pause-button icon-lg"
                    on:click={() => {
                        const action = $playerInfoStore.status === "paused" ? PlayerActionTypes.Play : PlayerActionTypes.Pause;
                        sendToHostAsync(ClientMessageTypes.PlayerAction, { action });
                    }}
                    disabled={!$playerInfoStore.song}
                >
                    <i class={`fa-solid ${$playerInfoStore.status === "paused" ? "fa-play" : "fa-pause"}`} />
                </button>
            </div>
        </div>

        <div class="bottom-controls">
            <button class="icon-sm set-snap-button" disabled={!$playerInfoStore.song} on:click={() => addSnap()}>
                <span class="fa-stack" style="font-size:0.5em">
                    <i class="fa-solid fa-location-pin fa-stack-2x" />
                    <i class="fa-solid fa-plus fa-stack-1x fa-inverse" />
                </span>
            </button>
            <button class="icon-lg load-song-button" on:click={() => selectSong()}>
                <i class="fa-solid fa-music" />
                <i class="fa-solid fa-ellipsis" />
            </button>
        </div>
    {:else}
        <div class="no-current-song gray-message">Aucune musique en cours</div>
    {/if}
</div>

<SelectSongDialog bind:this={selectSongDialog} enableCoachRequest={false} />

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="player"] {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .song-info {
            background-color: $song-color;
            border: 1px solid $secondary;
            border-radius: 5px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .song-status {
                display: grid;

                .player-progress {
                    grid-row: 1;
                    grid-column: 1;

                    &.ghost {
                        opacity: 0.4;
                    }
                }

                .player-status {
                    grid-row: 1;
                    grid-column: 1;
                    z-index: 10;
                    display: flex;
                    justify-content: center;
                    align-items: baseline;
                    gap: 1rem;
                    font-size: 2rem;

                    color: $bold;
                    i {
                        font-size: 2rem;
                    }
                }
            }

            .song-name {
                display: flex;
                font-size: 1.5rem;
                justify-content: center;
                align-items: center;
                gap: 1rem;
            }

            .choose-music {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;
            }
        }

        .controls {
            display: flex;
            justify-content: space-between;

            .button-section {
                display: flex;
                gap: 1rem;
            }

            .play-pause-button {
                height: 4rem;
            }
        }

        .bottom-controls {
            display: flex;
            justify-content: space-between;
        }

        .no-current-song {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
