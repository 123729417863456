<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { fade, scale } from "svelte/transition";
  import { DialogResult, type IDialogConfig } from "../models";
  import { Deferred } from "../utils/deferred-promise";

  export let config: IDialogConfig;

  let defaultConfig: IDialogConfig = {
    title: "",
    contentText: "",
    contentTextIsHtml: false,
    showCancel: false,
    showOk: false,
    showClose: false,
    showX: false,
    dialogHeight: undefined,
    okCustomText: undefined,
    cancelCustomText: undefined,
    autoSize: true,
    headerCentered: false,
    headerIcon: undefined,
    contentPadding: undefined,
    width: undefined,
  };

  let closePromise: Deferred<DialogResult>;
  const dispatch = createEventDispatcher();

  onMount(() => {
    config = {
      ...defaultConfig,
      ...config,
    };
  });

  export const openDialogAsync = async (): Promise<DialogResult> => {
    isDialogOpened = true;
    closePromise = new Deferred<DialogResult>();
    return closePromise;
  };

  let isDialogOpened = false;

  /**
   * This method is called externally to actually close the dialog
   */
  export const closeDialog = () => {
    closeInternal(DialogResult.closeExternal);
  };

  const closeInternal = (action: DialogResult) => {
    closePromise.resolve(action);
    dispatch("close", action);
    isDialogOpened = false;
  };
</script>

<!-- ######################################################################################### -->
{#if isDialogOpened}
  <div data-component="Modal">
    <div class="backdrop" transition:fade />
    <div class="dialog-container">
      <div class="dialog" in:scale out:fade style={config.autoSize ? "" : `height:${config.dialogHeight}`}>
        <div class="dialog-content" style={`height:${config.autoSize ? "auto" : "100%"}; ${config.width ? "width:" + config.width : ""}; `}>
          <div class="header">
            <div class="header-content" class:centered={config.headerCentered}>
              <div class="empty" />
              <div class="title">
                {#if config.headerIcon}
                  <i class={config.headerIcon} />
                {/if}
                <span>{config.title}</span>
              </div>
              <div class="close">
                {#if config.showX}
                  <i class="fa-solid fa-close clickable" on:click={() => closeInternal(DialogResult.close)} />
                {/if}
              </div>
            </div>
          </div>

          <div class="content" style={config.contentPadding ? `padding:${config.contentPadding}` : undefined}>
            {#if isDialogOpened}
              <slot />
            {/if}
          </div>

          <div class="footer">
            <div class="footer-content">
              {#if config.showCancel}
                <button on:click={() => closeInternal(DialogResult.cancel)}><span>{config.cancelCustomText || "Annuler"}</span> </button>
              {/if}
              {#if config.showClose}
                <button on:click={() => closeInternal(DialogResult.close)}>Fermer</button>
              {/if}
              {#if config.showOk}
                <button on:click={() => closeInternal(DialogResult.ok)}>
                  <span>{config.okCustomText || "Ok"}</span>
                </button>
              {/if}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<!-- ######################################################################################### -->
<style lang="scss">
  [data-component="Modal"] {
    .backdrop {
      background-color: #44444488;
      width: 100vw;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    .dialog-container {
      z-index: $modal-dialog-z-index;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      .dialog {
        overflow: hidden;
        border: 0;
        padding: 0;
        box-shadow: 10px 10px 15px #444444;
        border-radius: 5px;
        max-width: 90vw;

        &::backdrop {
          background: rgba(0, 0, 0, 0.5);
        }

        .dialog-content {
          display: flex;
          flex-direction: column;
          background-color: $background-light;
          border-radius: 5px;

          .header {
            background-color: $primary;
            color: white;

            .header-content {
              display: grid;

              grid-template-columns:
                0
                auto
                minmax(max-content, 1fr);

              &.centered {
                grid-template-columns:
                  minmax(max-content, 1fr)
                  auto
                  minmax(max-content, 1fr);
              }
              align-items: center;
              padding: 1rem;
              font-size: 2rem;
            }

            .title {
              font-size: 1.5rem;
              white-space: nowrap;

              i {
                margin-right: 0.5rem;
              }
            }

            .close {
              display: flex;
              justify-content: flex-end;
              font-size: 2rem;
              margin-left: 1rem;
            }
          }
          .content {
            flex: auto;
            overflow: hidden;
            padding: 1rem;
          }

          .footer {
            .footer-content {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 1rem;
              padding: 1rem;

              button {
                min-width: 130px;
              }
            }
          }
        }
      }
    }
  }
</style>
