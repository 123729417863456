
export const round = (value: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}

export const slugify = (str = "") =>
    str.toLowerCase().replace(/ /g, "-").replace(/\./g, "");


export const millisecondsToTime = (ms: number) => {
    return secondsToTime(ms / 1000);

}

export const secondsToTime = (sec: number) => {

    const intSeconds = Math.trunc(sec > 0 ? sec : 0);
    const seconds = intSeconds % 60;
    const minutes = (intSeconds - seconds) / 60;

    return `${minutes.toString().padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
}


export const sum = (data: number[]) => {
    return data.reduce((a, b) => a + b, 0)
}


export const minmax = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);


export const findLast = <T>(array: T[], predicate: (t: T) => boolean): T => {
    for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (predicate(x)) {
            return x;
        }
    }
}
export const findLastIndex = <T>(array: T[], predicate: (t: T) => boolean) => {
    for (let i = array.length - 1; i >= 0; --i) {
        const x = array[i];
        if (predicate(x)) {
            return array.indexOf(x);
        }
    }
    return -1;
}

export const getSieblingsAtIndex = (
    arr: any[],
    index: number,
    sieblingsCount: number,
    useSelf = false
) => {
    if (sieblingsCount === 0) {
        return;
    }

    let startIndex = useSelf ? index : sieblingsCount > 0 ? index + 1 : index - 1;

    let secondIndex = useSelf
        ? index + sieblingsCount - 1
        : index + sieblingsCount;

    startIndex = validateArrayIndex(arr, startIndex);
    secondIndex = validateArrayIndex(arr, secondIndex);

    if (startIndex == secondIndex) {
        return [];
    }

    return arr.slice(
        Math.min(startIndex, secondIndex),
        Math.max(startIndex, secondIndex) + 1
    );
};

export const validateArrayIndex = (array: any[], index: number) => {
    return Math.min(Math.max(index, 0), array.length - 1);
};


export const logdev = (message?: any, ...optionalParams: any[]) => {
    if (import.meta.env.DEV) {
        console.log(message, ...optionalParams)
    }
}

export const logdevCond = (condition: boolean, message?: any, ...optionalParams: any[]) => {
    if (!condition) return;
    if (import.meta.env.DEV) {
        console.log(message, ...optionalParams)
    }
}


export const randomNumber = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
}
export const randomInt = (min: number, max: number) => {
    return Math.round(Math.random() * (max - min) + min);
}
export const shuffleArray = (array: any[]) => {

    const newArray = [...array];

    let currentIndex = newArray.length
    let randomIndex = 0;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [newArray[currentIndex], newArray[randomIndex]] = [
            newArray[randomIndex], newArray[currentIndex]];
    }

    return newArray;


}