import { get } from "svelte/store";
import { libraryStore } from "../stores/session-store";
import type { IQueuedSongDTO } from "./DTO";
import type { ISignalRConnectionInfo } from "./ISignalRConnectionInfo";

export class QueuedSong {

    id: string;
    name: string;
    parentId: string;
    duration: number;

    qId: string;
    isCoachRequest: boolean;
    overrideSpecificPosition?: number;
    queuedTime: Date;
    startTime?: Date;
    sessionOccurence: number;
    requestedBy: ISignalRConnectionInfo;
    plannedStartTime: Date;
    queueStatus: "current" | "queued" | "history";
    playStatus: "none" | "playing" | "skipped" | "completed";


    constructor(dto: IQueuedSongDTO) {
        Object.assign(this, dto);

        const song = get(libraryStore).songsFlat.find(s => s.id === dto.id);
        Object.assign(this, song);

        this.plannedStartTime = new Date(dto.plannedStartTime);
        this.startTime = dto.startTime ? new Date(dto.startTime) : undefined;
        this.queuedTime = dto.queuedTime ? new Date(dto.queuedTime) : undefined;
        this.queuedTime
    }
}
