import type { ISessionInfo } from "../models/ISessionInfo";

export enum LocalStorageKey {
    sessionInfo = "sessionInfo",
    deviceUserId = "deviceUserId",
    username = "username",
    isCoach = "isCoach"
}


export class AppLocalStorage {

    public clearAll = () => {
        localStorage.clear();
    }

    public get sessionInfo(): ISessionInfo {
        const data = localStorage.getItem(LocalStorageKey.sessionInfo);
        if (!data) {
            return undefined;
        }
        const sessionInfo = JSON.parse(data);
        if (sessionInfo.connectedDate) {
            sessionInfo.connectedDate = new Date(sessionInfo.connectedDate);
        }

        return sessionInfo;
    }
    public set sessionInfo(value: ISessionInfo) {
        if (!value) {
            localStorage.removeItem(LocalStorageKey.sessionInfo);
            return;
        }
        localStorage.setItem(LocalStorageKey.sessionInfo, JSON.stringify(value));
    }





    public get deviceUserId(): string {
        return localStorage.getItem(LocalStorageKey.deviceUserId);
    }
    public set deviceUserId(value: string) {
        localStorage.setItem(LocalStorageKey.deviceUserId, value);
    }



}

export const appLocalStorage = new AppLocalStorage();
