<script lang="ts">
  import { createEventDispatcher, onMount } from "svelte";
  import { get } from "svelte/store";
  import type { IFolder, ISong } from "../../models";
  import { libraryStore } from "../../stores/session-store";
  import FolderBox from "./folder-box.svelte";
  import Songbox from "./song-box.svelte";

  const dispatch = createEventDispatcher();

  export let currentFolder: IFolder;
  export let disabledIds = [];
  export let songSelectionEnabled = true;

  const changeFolder = (folderId: string) => {
    currentFolder = get(libraryStore).foldersFlat.find(
      (f) => f.id === folderId
    );
  };

  onMount(() => {
    currentFolder = get(libraryStore).root;
  });

  const songClicked = (song: ISong) => {
    dispatch("songSelected", song);
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="NavigateFolderSong">
  {#if currentFolder}
    {#if currentFolder?.parentId}
      <div class="top-nav">
        <button
          class="icon-md"
          on:click={() => changeFolder(currentFolder?.parentId)}
        >
          <i class="fa-solid fa-angles-left back" />
        </button>

        <div class="current-folder">
          <i class="fa-regular fa-folder-open" />
          {currentFolder?.name}
        </div>
      </div>
    {/if}

    <div class="list">
      <div class="list-items">
        {#each currentFolder?.folders as folder (folder.id)}
          <div
            class="list-item"
            class:disabled={disabledIds.indexOf(folder.id) >= 0}
          >
            <FolderBox
              {folder}
              clickable={true}
              on:click={() => changeFolder(folder.id)}
            />
          </div>
        {/each}

        {#each currentFolder?.songs as song (song.id)}
          {#if songSelectionEnabled}
            <div
              class="list-item"
              class:disabled={disabledIds.indexOf(song.id) >= 0}
            >
              <Songbox
                clickable={true}
                {song}
                on:click={() => songClicked(song)}
              />
            </div>
          {:else}
            <div class="simple-song">{song.name}</div>
          {/if}
        {/each}
      </div>
    </div>
  {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="NavigateFolderSong"] {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 700px;

    .top-nav {
      display: flex;
      align-items: center;
      gap: 2rem;
      font-size: 1.5rem;
      padding: 1rem 0 1rem 0;

      .current-folder {
        flex: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .back {
        cursor: pointer;
      }
    }

    .list {
      flex: auto;
      overflow: auto;

      .list-items {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .list-item {
          &.disabled {
            opacity: 0.4;
          }
        }
      }

      .simple-song {
        margin-left: 2rem;
      }
    }
  }
</style>
