<script lang="ts">
  export let title = "";
  export let contentPadding: string = undefined;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="Panel">
  <div class="main">
    <div class="top">
      <div>
        <slot name="header-left" />
      </div>
      <div class="title">{title}</div>
      <div>
        <slot name="header-right" />
      </div>
    </div>

    <div
      class="content"
      style={contentPadding ? `padding:${contentPadding}` : undefined}
    >
      <slot />
    </div>
  </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  $border-width: 2px;

  [data-component="Panel"] {
    height: 100%;

    .main {
      display: flex;
      flex-direction: column;
      height: 100%;

      .top {
        display: grid;
        grid-template-columns:
          minmax(max-content, 1fr)
          auto
          minmax(max-content, 1fr);
        align-items: center;
        :last-child {
          margin-left: auto;
        }
        gap: 1rem;

        background-color: $primary;
        height: 1.5rem;
        color: white;
        border-radius: 5px 5px 0 0;
        padding: 0.5rem 1rem;

        .title {
          font-weight: bold;
          text-align: center;
        }
      }

      .content {
        flex: auto;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        border-width: 0 $border-width $border-width $border-width;
        border-style: solid;
        border-color: $primary;
        padding: 1rem;
      }
    }
  }
</style>
