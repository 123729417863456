<script lang="ts">
    import GroupPlayer from "./components/group-player.svelte";
    import Panel from "./components/panel.svelte";
    import Playlist from "./components/playlist.svelte";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="GroupSession">
    <div class="panel">
        <Panel title="MUSIQUE COURANTE">
            <GroupPlayer />
        </Panel>
    </div>

    <div class="panel">
        <Playlist />
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="GroupSession"] {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .panel {
            box-shadow: 0px 0px 15px #aaaaaa;
            border-radius: 5px;
            overflow: hidden;
        }

        .add-button {
            margin-top: 1rem;

            .add-button-content {
                display: flex;
                gap: 1rem;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>
