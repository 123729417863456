<script lang="ts">
  import { onMount } from "svelte";
  import ConfirmDialog from "../../dialogs/confirm-dialog.svelte";
  import {
    appConfirmDialogStoreClosePromise,
    appConfirmDialogStoreOpen,
  } from "../../stores/app-store";

  let dialog: ConfirmDialog;

  onMount(() => {
    appConfirmDialogStoreOpen.subscribe(async (config) => {
      if (config) {
        const res = await dialog.openDialogAsync(config);
        appConfirmDialogStoreClosePromise.resolve(res);
      }
    });
  });
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<ConfirmDialog bind:this={dialog} />
