<script lang="ts">
  import { get } from "svelte/store";
  import Qrcode from "../lib/components/qrcode.svelte";
  import SignalrConnectionIcon from "../lib/components/signalr-connection-icon.svelte";
  import { sessionInfoStore } from "../stores/session-store";
  import { connectToSignalR, sessionConnectionStatusStore } from "../stores/signalr-store";
  import Modal from "./modal.svelte";

  let modal: Modal;

  let sessionUrl: string;

  export const openDialogAsync = () => {
    const sessionId = get(sessionInfoStore).sessionId;
    if (sessionId) {
      sessionUrl = `${window.location.origin}?sId=${sessionId}`;
    }
    return modal.openDialogAsync();
  };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<Modal
  bind:this={modal}
  config={{
    title: "Session",
    showClose: true,
    showX: true,
  }}
>
  <div data-component="SessionQrcodeDialog">
    {#if $sessionConnectionStatusStore.connected}
      <div class="session-info-content">
        <div class="mobile-app-url">
          {window.location.origin}
        </div>

        <div class="table">
          <div class="mini-panel">
            <div class="title">CODE DE SESSION</div>
            <div class="content session-code">
              {$sessionInfoStore.sessionId}
            </div>
          </div>

          <div class="mini-panel">
            <div class="title">CODE QR</div>
            <div class="content">
              <Qrcode url={sessionUrl} />
            </div>
          </div>
        </div>
      </div>
    {:else}
      <div class="connection-info">
        <div class="icon">
          <SignalrConnectionIcon status={$sessionConnectionStatusStore} />
        </div>
        {#if !$sessionConnectionStatusStore.connected}
          <button on:click={() => connectToSignalR()}>Reconnecter</button>
        {:else}
          <div>Connexion au serveur...</div>
        {/if}
      </div>
    {/if}
  </div>
</Modal>
<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
  [data-component="SessionQrcodeDialog"] {
    height: 100%;
    overflow: auto;
    width: min(90vw, 25rem);

    .connection-info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      .icon {
        font-size: 5rem;
      }
    }

    .session-info-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .mobile-app-url {
        background-color: $primary;
        color: white;
        padding: 0.5rem;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        text-align: center;

        a {
          text-decoration: none;
          color: white;
        }
      }

      .table {
        display: flex;
        gap: 1rem;
      }

      .mini-panel {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .title {
          background-color: $primary;
          color: white;
          text-align: center;
          font-size: 1rem;
          padding: 0.5rem 1rem;
          border-radius: 0.2rem 0.2rem 0 0;
        }
        .content {
          flex: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-width: 0 2px 2px 2px;
          border-style: solid;
          border-color: gray;
          padding: 0.5rem;
        }
      }

      .session-code {
        color: $bold;
        text-decoration: none;
        font-weight: bold;
        font-size: 2rem;
        font-family: "Andale Mono Regular";
      }

      .connected-users-header {
        display: flex;
        align-items: center;
        gap: 1rem;

        .connected-users-count {
          font-weight: bold;
        }
      }
      .connected-users {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
      }
    }
  }
</style>
