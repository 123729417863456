<script lang="ts">
    import { format } from "date-fns";
    import { createEventDispatcher } from "svelte";
    import type { QueuedSong } from "../../models/QueuedSong";
    import { millisecondsToTime } from "../../utils/general";
    import UserTag from "./user-tag.svelte";

    export let song: QueuedSong;
    export let showClose = false;
    export let clickable: boolean = false;

    const dispatch = createEventDispatcher();
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div
    data-component="QueuedSongBox"
    on:click={() => dispatch("click")}
    class:clickable
>
    <div class="song-info">
        <i class="fa-solid fa-music song-icon" />

        <div class="song-identifier">
            <div class="song-text">{song.name}</div>
            <div class="song-length">
                ({millisecondsToTime(song.duration)})
            </div>
        </div>
    </div>

    <div class="request-info">
        {#if song.overrideSpecificPosition}
            <i class="fa-solid fa-arrow-up-1-9" />
        {/if}
        {#if song.isCoachRequest}
            <i class="fa-solid fa-user-group" />
        {/if}
        {#if song.requestedBy}
            <UserTag username={song.requestedBy.username} />
        {/if}
    </div>

    <div class="planned-time">
        <i class="fa-regular fa-clock" />
        <div>{format(song.plannedStartTime, "HH:mm")}</div>
    </div>

    <div class="close-button">
        <i
            class:hide={!showClose}
            class="fa-solid fa-close remove"
            on:click={() => dispatch("removeFromQueue", song.qId)}
        />
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="QueuedSongBox"] {
        display: grid;
        grid-template-areas: "songInfo requestInfo plannedTime closeButton";
        grid-template-columns: 1fr auto auto auto;

        align-items: center;
        gap: 1rem;
        background-color: $song-color;
        border: 1px solid $secondary;
        border-radius: 5px;
        color: black;
        padding: 0.5rem 1rem;
        overflow: hidden;

        @media (max-width: 500px) {
            grid-template-areas:
                "songInfo songInfo closeButton"
                "plannedTime requestInfo requestInfo";
            grid-template-rows: auto auto;
            grid-template-columns: 1fr;
            gap: 0.2rem;
        }

        .song-info {
            grid-area: songInfo;
            display: flex;
            gap: 1rem;
            align-items: center;

            .song-icon {
                font-size: 1.5rem;
            }

            .song-identifier {
                display: flex;
                flex: auto;
                align-items: center;
                gap: 0.5rem;
                overflow: hidden;

                .song-text {
                    font-weight: bold;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .song-length {
                    font-size: 0.8rem;
                    display: flex;
                    flex-wrap: nowrap;
                }
            }
        }

        .request-info {
            grid-area: requestInfo;
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        .planned-time {
            grid-area: plannedTime;
            display: flex;
            align-items: baseline;
            gap: 0.3em;
        }

        .close-button {
            grid-area: closeButton;
            font-size: 1.5rem;
            font-weight: bold;
            cursor: pointer;
            justify-self: flex-end;
            margin-right: 2px;
        }
    }
</style>
