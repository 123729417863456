<script lang="ts">
    import { logDebugData } from "../../stores/app-store";

    let show = false;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="DebugInfo">
    <div class="top-buttons">
        <a on:click={() => (show = !show)}>
            <i class="fa-solid fa-screwdriver-wrench" />
        </a>
        {#if show}
            <button on:click={() => logDebugData.set([])}>Clear Log</button>
        {/if}
    </div>
    {#if show}
        <div class="log-container">
            {#each $logDebugData as line}
                <div class="line">
                    <span class="time">{line.time}</span>
                    <span class="msg">{line.msg}</span>
                </div>
            {/each}
        </div>
    {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="DebugInfo"] {
        a {
            color: gray;
        }

        .top-buttons {
            display: flex;
            gap: 1rem;
            margin-bottom: 0.5rem;
        }

        .log-container {
            height: 200px;
            overflow: auto;
            margin-top: 0.5rem;
            background-color: lightblue;
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            margin-bottom: 0.5rem;

            .line {
                white-space: nowrap;
            }
            .time {
                font-size: 0.6rem;
                color: gray;
            }
            .msg {
                font-size: 0.8rem;
            }
        }
    }
</style>
