<script lang="ts">
  import { debounceTime, interval, Subject } from "rxjs";
  import { generate } from "short-uuid";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { fade } from "svelte/transition";
  import type { IPlayerInfo } from "../../models/IPlayerInfo";
  import { secondsToTime } from "../../utils/general";
  import { clearSubscriptions, safeSubscribe } from "../../utils/rx-utils";

  const dispatch = createEventDispatcher();

  export let playerInfo: IPlayerInfo;
  export let snapThreshold = 3;
  export let allowDrag = false;
  export let time = 0;

  const cId = generate();

  let thumbValue: number = 0;
  let dragging = false;
  let progressRatio = 0;

  let seeker: HTMLInputElement;

  const thumbDroppedSubject = new Subject<void>();

  $: duration = playerInfo?.song?.duration / 1000 || 0;

  $: {
    updateTime(playerInfo?.time || 0);
  }

  $: snapTime = playerInfo.snapTime;

  $: snapTimeRatio = snapTime && duration ? (snapTime / duration) * 100 : 0;

  $: {
    progressRatio = duration ? thumbValue / duration : 0;
    seeker?.style.setProperty("--ratio", progressRatio.toString());
  }

  onMount(() => {
    thumbValue = time;

    safeSubscribe(
      cId,
      interval(200).subscribe(() => {
        if (playerInfo?.status === "playing") {
          updateTime(time + 0.2);
        }
      }),
      thumbDroppedSubject.pipe(debounceTime(100)).subscribe(() => thumbDropped())
    );
  });

  onDestroy(() => {
    clearSubscriptions(cId);
  });

  export const setPosition = (seconds: number) => {
    seconds = Math.max(0, Math.min(seconds, duration));
    updateTime(seconds);
  };
  export const setPositionAdd = (seconds: number) => {
    setPosition(time + seconds);
  };

  export const setSnap = (time: number = undefined) => {
    if (time) {
      snapTime = Math.max(0, Math.min(time, duration));
    } else {
      snapTime = time;
    }
  };
  export const clearSnap = () => {
    snapTime = undefined;
  };

  export const goToSnap = () => {
    setPosition(snapTime);
  };

  const updateTime = (value: number) => {
    time = value;
    seeker?.style.setProperty("--value", time.toString());

    if (!dragging) {
      thumbValue = value;
    }
  };

  const thumbDragging = () => {
    if (snapTime) {
      if (Math.abs(thumbValue - snapTime) < snapThreshold) {
        thumbValue = snapTime;
      }
    }
  };

  const thumbDropped = () => {
    dragging = false;
    setPosition(thumbValue);
    dispatch("timeUpdated", thumbValue);
  };
</script>

<!-- ########################################################################################### -->
{#if dragging}
  <!-- DRAGGING THUMB -->
  <div class="thumb-drag-container" transition:fade>
    <div style="flex:{progressRatio} 1 auto" />
    <div class="thumb-drag-flyout">
      <div class="thumb-drag-flyout-text">
        {secondsToTime(thumbValue)}
      </div>
    </div>
    <div style="flex:{1 - progressRatio} 1 auto" />
  </div>
{/if}

<div class="top-container">
  <!-- SNAP PIN -->
  {#if snapTime}
    <div class="snap-pin-container">
      <div class="snap-pin-moveable-container" style="transform: translateX({snapTimeRatio}%)">
        <i class="snap-pin fa-solid fa-location-pin" />
      </div>
    </div>
  {/if}

  <!-- TOP TIMERS -->
  <div class="top-timers">
    <div>{secondsToTime(time)}</div>
    <div>{secondsToTime(duration - time)}</div>
  </div>
</div>

<input
  bind:this={seeker}
  disabled={!allowDrag}
  type="range"
  min="0"
  max={duration}
  bind:value={thumbValue}
  step="0.2"
  class:empty-media={!duration}
  on:input={() => thumbDragging()}
  on:change={() => thumbDroppedSubject.next()}
  on:mousedown={() => (dragging = true)}
  on:mouseup={() => thumbDroppedSubject.next()}
  on:pointerdown={() => (dragging = true)}
  on:pointerup={() => thumbDroppedSubject.next()}
  on:touchstart={() => (dragging = true)}
  on:touchend={() => thumbDroppedSubject.next()}
/>

<!-- ########################################################################################### -->
<style lang="scss">
  $range-color-left: $primary;
  $range-color-right: #cccccc;
  $range-height: 20px;
  $range-track-radius: 5px;

  $range-thumb-color: #ffffff;
  $range-thumb-height: 20px;
  $range-thumb-width: 40px;
  $range-thumb-border-radius: 5px;

  input[type="range"] {
    width: 100%;
    height: $range-height;
    border-radius: $range-track-radius;
    appearance: none;
    -webkit-appearance: none;

    --ratio: 0;
    --sx: calc(0.5 * #{$range-thumb-width} + var(--ratio) * (100% - #{$range-thumb-width}));
    background: linear-gradient($range-color-left, $range-color-left) 0 / var(--sx) 100% no-repeat, $range-color-right;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: $range-thumb-width;
      height: $range-thumb-height;
      border-radius: $range-thumb-border-radius;
      background: $range-thumb-color;

      box-shadow: 0 0 2px black;

      transition: transform 0.2s ease-in-out;
      &:active {
        transform: scaleY(1.2);
        transition: transform 0.2s ease-in-out;
      }
    }

    &.empty-media {
      background-color: #f1f1f1;
      opacity: 0.8;
      background-size: 10px 10px;
      background-repeat: repeat;
      background-image: repeating-linear-gradient(45deg, #9f9f9f 0, #9f9f9f 1px, #f1f1f1 0, #f1f1f1 50%);
      &::-webkit-slider-thumb {
        width: 0;
        height: 0;
      }
    }
  }

  .thumb-drag-container {
    font-size: 0.8rem;
    height: 0px;
    display: flex;
    // This is important to align text with thumb
    // -30px is for textWidth(100px) - thumbWidth(40px) / 2 -->
    margin: 0 -30px;
  }

  .thumb-drag-flyout {
    width: 100px;
    display: flex;
    justify-content: center;
  }

  .thumb-drag-flyout-text {
    background-color: $primary;
    color: white;
    border-radius: 5px;
    padding: 2px 14px;
    text-align: center;
    width: fit-content;
    height: fit-content;
    transform: translate(1px, -10px);
  }

  .top-container {
    position: relative;
    .top-timers {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
    }

    .snap-pin-container {
      position: absolute;
      width: 100%;

      .snap-pin-moveable-container {
        margin: 0 20px;

        .snap-pin {
          transform: translateX(-50%) translateY(-0.2rem);
          color: $primary;
        }
      }
    }
  }
</style>
