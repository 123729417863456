import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { filter, firstValueFrom, interval, map, race, Subject } from 'rxjs';
import { get, writable } from 'svelte/store';
import type { ISignalrMessage } from '../models';
import type { IFullSessionDataDTO, IGroupPlayerInfoDTO, IGroupSessionDataDTO, ILibraryDTO, IQueuedSongDTO, ISingleSessionDataDTO } from '../models/DTO';
import type { ISinglePlayerInfoDTO } from '../models/DTO/ISinglePlayerInfoDTO';
import { HostMessageTypes, SessionType } from '../models/enums';
import { ClientMessageTypes } from "../models/enums/ClientMessageTypes";
import type { ISignalRConnectionInfo } from '../models/ISignalRConnectionInfo';
import type { SongSelectionResult } from '../models/song-selection-result';
import { appLocalStorage } from '../utils/app-local-storage';
import { unzip } from '../utils/zip';
import { appPageStore, logDebug } from './app-store';
import { sessionDataStore, sessionInfoStore, updateGroupPlayer, updateLibraryStore, updateQueueStore, updateSinglePlayer } from './session-store';
import { showToast, ToastType } from './toaster-store';
const baseFunctionsUrl = import.meta.env.VITE_AZURE_FUNCTIONS_URL;

export interface SessionConnectionStatus {
    connected: boolean;
    signalrConnection: "disconnected" | "connecting" | "connected" | "error";
    hostConnection: "disconnected" | "connecting" | "connected" | "error";
}


let signalRHub: HubConnection;


export const sessionConnectedDelayed = writable<boolean>(false);
export const sessionConnectionStatusStore = writable<SessionConnectionStatus>({ connected: false, signalrConnection: "disconnected", hostConnection: "disconnected" });
sessionConnectionStatusStore.subscribe(async (status) => {
    if (status.connected) {
        await firstValueFrom(interval(1000));
        sessionConnectedDelayed.set(true);
    } else {
        sessionConnectedDelayed.set(false);
    }
})
const signalRHostMessageSubject = new Subject<{ messageType: HostMessageTypes, data: any }>();
export const signalRHostMessage$ = signalRHostMessageSubject.asObservable();

signalRHostMessage$.subscribe(msg => {
    logDebug("SIGNALR MESSAGE : " + msg.messageType)
})

export const connectToSignalR = async (): Promise<boolean> => {

    sessionConnectionStatusStore.set({ connected: false, signalrConnection: "connecting", hostConnection: "disconnected" })
    const sessionId = get(sessionInfoStore).sessionId

    try {

        // If connection exists, disconnect
        if (signalRHub) {
            await disconnectSignalrAsync();
        }

        signalRHub = new HubConnectionBuilder()
            .withUrl(baseFunctionsUrl, { withCredentials: false })
            .withAutomaticReconnect()
            .build();

        await signalRHub.start();

        subscribeToMessages();

        sessionConnectionStatusStore.update(status => ({ ...status, signalrConnection: "connected", hostConnection: "connecting" }))

        let success = await registerToSession(sessionId);
        if (!success) {
            sessionConnectionStatusStore.update(status => ({ ...status, hostConnection: "error" }));
            await disconnectSignalrAsync()
            logDebug("SIGNALR Connection Error, registerToSession failed");
            return;
        }

        success = await requestAndUpdateSessionAsync();
        if (!success) {
            sessionConnectionStatusStore.update(status => ({ ...status, hostConnection: "error" }));
            await disconnectSignalrAsync()
            logDebug("SIGNALR Connection Error, register to host failed");
            return;
        }

        sessionConnectionStatusStore.set({ connected: true, signalrConnection: "connected", hostConnection: "connected" })
        return true;
    }
    catch (ex) {
        console.error("ERROR CONNECTING TO SIGNALR", ex)
        return false;
    }

}

const registerToSession = async (sessionId: string): Promise<boolean> => {


    const data: ISignalRConnectionInfo = {
        connectionId: signalRHub.connectionId,
        deviceUserId: appLocalStorage.deviceUserId,
        username: get(sessionInfoStore).username,
        isHost: false,
    }

    const res = await fetch(
        `${baseFunctionsUrl}/RegisterToSession?isHost=true&sessionId=${sessionId}`,
        {
            method: "post",
            cache: "no-store",
            body: JSON.stringify(data)
        }
    );

    return res.ok
}


const unsubscribeAllMessages = (hub: HubConnection) => {

    hub.onclose = undefined;
    hub.onreconnected = undefined;
    hub.onreconnecting = undefined;
    hub.off("ping")
    hub.off(HostMessageTypes.SessionInfo)
    hub.off(HostMessageTypes.LibraryUpdate)
    hub.off(HostMessageTypes.QueueUpdate)
    hub.off(HostMessageTypes.GroupPlayerUpdate)
}


const subscribeToMessages = () => {

    signalRHub.on("ping", data => {
        const msg: ISignalrMessage<string> = JSON.parse(data);
        logDebug(`received PING from ${msg.from.isHost ? 'HOST' : msg.from.username}`)
        sendPong(msg.from.connectionId, msg.data);
    })

    // Use the signalRConnection Subject to observe messages
    signalRHub.on(HostMessageTypes.SessionInfo, data => {
        signalRHostMessageSubject.next({ messageType: HostMessageTypes.SessionInfo, data })
    })

    signalRHub.on(HostMessageTypes.LibraryUpdate, data => {
        try {
            const msg: ISignalrMessage<ILibraryDTO> = JSON.parse(data);
            if (!checkMessageStillValid(msg, HostMessageTypes.LibraryUpdate)) {
                return;
            }
            updateLibraryStore(msg.data);
            logDebug("SIGNALR on:Library Update")
        } catch (ex) {
            console.error("SignalR Error - on:LibraryUpdate", ex)
        }
    })

    signalRHub.on(HostMessageTypes.QueueUpdate, data => {
        try {
            const msg: ISignalrMessage<IQueuedSongDTO[]> = JSON.parse(data);
            if (!checkMessageStillValid(msg, HostMessageTypes.QueueUpdate)) {
                return;
            }

            updateQueueStore(msg.data);
            logDebug("SIGNALR on:Queue Update")

        } catch (ex) {
            console.error("SignalR Error - on:QueueUpdate", ex)
        }
    })

    signalRHub.on(HostMessageTypes.GroupPlayerUpdate, data => {
        try {
            const msg: ISignalrMessage<IGroupPlayerInfoDTO> = JSON.parse(data);
            if (!checkMessageStillValid(msg, HostMessageTypes.GroupPlayerUpdate)) {
                return;
            }

            updateGroupPlayer(msg.data);

            if (msg.data) {
                logDebug(`SIGNALR on:Player GroupPlayerUpdate. ${msg.data?.status}: ${msg.data.time}`)
            } else {
                logDebug(`SIGNALR on:Player GroupPlayerUpdate. No current song`)
            }
        } catch (ex) {
            console.error("SignalR Error - on:GroupPlayerUpdate", ex)
        }
    })

    signalRHub.on(HostMessageTypes.SinglePlayerUpdate, data => {
        try {
            const msg: ISignalrMessage<ISinglePlayerInfoDTO> = JSON.parse(data);
            if (!checkMessageStillValid(msg, HostMessageTypes.SinglePlayerUpdate)) {
                return;
            }
            updateSinglePlayer(msg.data);

            if (msg.data) {
                logDebug(`SIGNALR on:Player SinglePlayerUpdate. ${msg.data?.status}: ${msg.data.time}`)
            } else {
                logDebug(`SIGNALR on:Player SinglePlayerUpdate. No current song`)
            }
        } catch (ex) {
            console.error("SignalR Error - on:SinglePlayerUpdate", ex)
        }
    })

    signalRHub.onclose(() => {
        logDebug("# SignalR onclose")
    })

    signalRHub.onreconnecting(() => {
        appPageStore.set("connecting");
        logDebug("# SignalR onreconnecting")
        sessionConnectionStatusStore.set({ connected: false, signalrConnection: "connecting", hostConnection: "disconnected" })
    })

    signalRHub.onreconnected(async () => {
        logDebug("# SignalR onreconnected")
        sessionConnectionStatusStore.set({ connected: false, signalrConnection: "connected", hostConnection: "connecting" })

        let success = await registerToSession(get(sessionInfoStore).sessionId);
        if (!success) {
            sessionConnectionStatusStore.update(status => ({ ...status, hostConnection: "error" }));
            await disconnectSignalrAsync()
            logDebug("SIGNALR Connection Error, registerToSession failed");
            showToast("Connection", "Échec de connexion au serveur de session", ToastType.danger);
            return;
        }

        success = await requestAndUpdateSessionAsync();
        if (!success) {
            sessionConnectionStatusStore.update(status => ({ ...status, hostConnection: "error" }));
            await disconnectSignalrAsync()
            logDebug("SIGNALR Connection Error, register to host failed");
            showToast("Connection", "Échec de connexion à l'hôte de la session", ToastType.danger);
            return;
        }

        sessionConnectionStatusStore.set({ connected: true, signalrConnection: "connected", hostConnection: "connected" })
    })

}

const lastMessageTSMap = new Map<HostMessageTypes, number>();
const checkMessageStillValid = (msg: ISignalrMessage<any>, messageType: HostMessageTypes) => {

    if (lastMessageTSMap.get(messageType) > msg.timestamp) {
        logDebug(`OLD MESSAGE RECEIVED: ${messageType}... ignored`)
        return false;  //reject message if received newer message
    }
    lastMessageTSMap.set(messageType, msg.timestamp);
    return true;
}

export const disconnectSignalrAsync = async () => {

    if (signalRHub) {
        unsubscribeAllMessages(signalRHub)
        signalRHub.stop();

        // Wait until hub is disconnected
        await firstValueFrom(interval(500).pipe(filter(() => {
            return signalRHub?.state === HubConnectionState.Disconnected;
        })));

        signalRHub = undefined;
    }

    // if (changeStatus) {
    //     sessionConnectionStatusStore.update(status => ({ ...status, connected: false }))
    // }
}

export const sendToHostAsync = async (messageType: ClientMessageTypes, content?: any) => {

    const sessioninfo = get(sessionInfoStore);

    const data: ISignalrMessage<any> = {
        timestamp: Date.now(),
        data: content,
        from: {
            connectionId: signalRHub.connectionId,
            username: sessioninfo.username,
            deviceUserId: appLocalStorage.deviceUserId,
            isHost: false
        }
    }

    const res = await fetch(
        `${baseFunctionsUrl}/SendToHost?sessionId=${sessioninfo.sessionId}&messageType=${messageType}`,
        {
            method: "post",
            body: JSON.stringify(data),
            cache: "no-store"
        }
    );

    if (!res.ok) {
        console.error(`Error sending message "${messageType}" to host`);
    }

}

const sendPong = async (connectionId: string, pingId: string) => {

    const data: ISignalrMessage<any> = {
        timestamp: Date.now(),
        from: {
            connectionId: signalRHub.connectionId,
            username: get(sessionInfoStore).username,
            deviceUserId: appLocalStorage.deviceUserId,
            isHost: false
        },
        data: pingId,
    }

    const res = await fetch(
        `${baseFunctionsUrl}/Pong?connectionId=${connectionId}`,
        {
            method: "post",
            cache: "no-store",
            body: JSON.stringify(data),
        }
    );

    if (!res.ok) {
        logDebug("PONG -> error")
    }

}

export const requestAndUpdateSessionAsync = async (): Promise<boolean> => {

    try {

        sendToHostAsync(ClientMessageTypes.RequestSessionInfo)

        // This waits until the host ansers our request
        const result = await firstValueFrom(
            race(
                signalRHostMessage$.pipe(filter(m => m.messageType === HostMessageTypes.SessionInfo)),
                interval(15000).pipe(map(() => undefined))
            )
        );

        if (!result) {
            return false;
        }

        const message: ISignalrMessage<IFullSessionDataDTO> = JSON.parse(result.data);

        const libData = unzip(message.data.libraryCompressed);
        const libDTO: ILibraryDTO = JSON.parse(libData);

        updateLibraryStore(libDTO);

        if (message.data.sessionType === SessionType.group) {
            const data = message.data as IGroupSessionDataDTO;
            updateQueueStore(data.queue)
            updateGroupPlayer(data.player)
            sessionDataStore.set(data);
        } else {
            const data = message.data as ISingleSessionDataDTO;
            updateSinglePlayer(data.player)
            sessionDataStore.set(data);
        }


        return true;
    } catch (ex) {
        console.error("SignalR Error - on:SessionInfo", ex)
        showToast("Échec de connexion", "Échec de récupération de la session de l'hôte", ToastType.danger);
    }

    return false;

}

export const requestAddToQueue = async (songSelection: SongSelectionResult): Promise<void> => {
    await sendToHostAsync(ClientMessageTypes.AddToQueue, songSelection);
}

export const requestRemoveFromQueue = async (songId: string): Promise<void> => {
    await sendToHostAsync(ClientMessageTypes.RemoveFromQueue, songId);
}
