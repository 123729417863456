<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { IFolder } from "../../models";

  export let folder: IFolder;
  export let clickable: boolean = false;

  const dispatch = createEventDispatcher();
</script>

<!-- COMPONENT folder-box -->
<div
  data-component="folder-box"
  on:click={(e) => dispatch("click", e)}
  class={clickable ? "clickable" : ""}
>
  <i class="fa-regular fa-folder" />
  <span>{folder.name}</span>
</div>

<!-- STYLES -->
<style lang="scss">
  [data-component="folder-box"] {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: bold;
    background-color: beige;
    border: 1px solid darken(beige, 40);
    border-radius: 10px;
    color: black;
    padding: 0 1rem;
    height: 3rem;

    i {
      font-size: 1.5rem;
    }

    span {
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
</style>
