<script lang="ts">
  import { onMount } from "svelte";
  import AppHeader from "./lib/app-header.svelte";
  import AppConfirmDialogWrapper from "./lib/components/app-confirm-dialog-wrapper.svelte";
  import DebugInfo from "./lib/components/debug-info.svelte";
  import Toaster from "./lib/components/toaster.svelte";
  import ConnectPage from "./lib/connect-page.svelte";
  import ConnectingPage from "./lib/connecting-page.svelte";
  import SessionPage from "./lib/session-page.svelte";
  import { appClickedSubject, appPageStore, initializeApp } from "./stores/app-store";

  onMount(() => {
    initializeApp();

    document.onvisibilitychange = () => {
      if (document.visibilityState === "visible") {
        // logDebug("JE SUIS REVENU");
      } else {
        // disconnectSignalR();
        // logDebug("SIGNALR disconnected manually");
      }
    };
  });

  const appClicked = () => {
    appClickedSubject.next();
  };
</script>

<main on:click={() => appClicked()}>
  <AppHeader />

  {#if $appPageStore === "connect"}
    <ConnectPage />
  {:else if $appPageStore === "connecting"}
    <ConnectingPage />
  {:else if $appPageStore === "session"}
    <SessionPage />
  {/if}

  <div class="debug-info">
    <hr />
    <DebugInfo />
  </div>
</main>

<!-- Confirm dialog that is used for all Generic Confirm Dialog called from app-store.showAppConfirmDialogAsync() -->
<AppConfirmDialogWrapper />

<Toaster />

<style lang="scss">
  .debug-info {
    margin-top: 100px;
  }
</style>
