<script lang="ts">
    import { onMount } from "svelte";
    import { appPageStore } from "../stores/app-store";
    import { sessionInfoStore } from "../stores/session-store";
    import { connectToSignalR, sessionConnectionStatusStore } from "../stores/signalr-store";
    import SignalrConnectionIcon from "./components/signalr-connection-icon.svelte";
    import SignalrConnectionVisual from "./components/signalr-connection-visual.svelte";

    const THRESHOLD_AUTO_RESTART_SESSION = 30; //30 MINUTES

    $: connectionInProgress =
        $sessionConnectionStatusStore.signalrConnection === "connecting" || $sessionConnectionStatusStore.hostConnection === "connecting";

    $: connectionError = $sessionConnectionStatusStore.signalrConnection === "error" || $sessionConnectionStatusStore.hostConnection === "error";

    onMount(async () => {
        const success = await connectToSignalR();

        if (success) {
            setTimeout(() => {
                appPageStore.set("session");
            }, 1000);
        }
    });

    const cancel = () => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("noAutoConnect", "1");
        window.location.replace(`${window.location.origin}?${urlParams.toString()}`);
    };
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="ConnectingPage">
    <div class="connecting-container">
        <div class="connection-icon">
            <SignalrConnectionIcon status={$sessionConnectionStatusStore} />
        </div>
        <div>
            <SignalrConnectionVisual />
            <div class="message">
                {#if $sessionConnectionStatusStore.signalrConnection === "connecting"}
                    <div>Connexion au serveur...</div>
                {:else if $sessionConnectionStatusStore.hostConnection === "connecting"}
                    <div>Connexion à l'hôte... <span class="sessionId">{$sessionInfoStore.sessionId}</span></div>
                {/if}
            </div>
        </div>

        <div>
            {#if connectionInProgress}
                <button on:click={() => cancel()}>Annuler la conection</button>
            {/if}

            {#if connectionError}
                <button on:click={() => cancel()}>Retour</button>
            {/if}

            {#if $sessionConnectionStatusStore.connected}
                <div>Connecté</div>
            {/if}
        </div>
    </div>
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="ConnectingPage"] {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2.5rem;
        max-width: 25rem;
        margin: 0 auto;

        .connecting-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            .connection-icon {
                font-size: 5rem;
            }
        }

        .message {
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;

            .sessionId {
                font-weight: bold;
            }
        }
    }
</style>
