<script lang="ts">
    import SessionInfoDialog from "../../dialogs/session-info-dialog.svelte";
    import { DialogResult } from "../../models";
    import { showAppConfirmDialogAsync } from "../../stores/app-store";
    import { sessionInfoStore } from "../../stores/session-store";
    import { sessionConnectionStatusStore } from "../../stores/signalr-store";
    import SignalrConnectionIcon from "./signalr-connection-icon.svelte";
    import UserTag from "./user-tag.svelte";

    let sessionInfoDialog: SessionInfoDialog;

    const quitSession = async () => {
        const res = await showAppConfirmDialogAsync({
            title: "Quitter",
            contentText: "Quitter la session?",
            showOk: true,
            showCancel: true,
            showX: true,
        });
        if (res !== DialogResult.ok) {
            return;
        }

        location.replace(location.origin);
        disconnectSignalR();
    };

    function disconnectSignalR() {
        throw new Error("Function not implemented.");
    }
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="SessionHeader">
    <div class="connection-info">
        <div class="signalr-connection-icon clickable" on:click={() => sessionInfoDialog.openDialogAsync()}>
            <SignalrConnectionIcon status={$sessionConnectionStatusStore} />
        </div>

        <UserTag username={$sessionInfoStore.username} />
    </div>
    <div>
        <div class="quit clickable" on:click={() => quitSession()}>
            <i class="fa-solid fa-close" />
            Quitter
        </div>
    </div>
</div>

<SessionInfoDialog bind:this={sessionInfoDialog} />

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SessionHeader"] {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .connection-info {
            display: flex;
            align-items: flex-end;
            gap: 1rem;

            color: $bold;
            font-weight: bold;

            .signalr-connection-icon {
                font-size: 2rem;
            }
        }

        .quit {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-weight: bold;

            i {
                font-size: 1.5rem;
            }
        }
    }
</style>
