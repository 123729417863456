<script lang="ts" context="module">
  export enum CheckboxStyle {
    Checkbox = "Checkbox",
    Radio = "Radio",
  }
</script>

<script lang="ts">
  export let checked = false;
  export let style = CheckboxStyle.Checkbox;
  export let label = undefined;
  export let labelOnLeft = false;

  $: unCheckedStyle =
    "fa-regular " +
    (style === CheckboxStyle.Checkbox ? "fa-square" : "fa-circle");

  $: checkedStyle =
    "fa-solid " +
    (style === CheckboxStyle.Checkbox ? "fa-square-check" : "fa-circle-check");

  $: currentClass = checked ? checkedStyle : unCheckedStyle;
</script>

<!-- COMPONENT checkbox -->
<div
  data-component="Checkbox"
  on:click={() => (checked = !checked)}
  class="clickable"
  class:reverse={labelOnLeft}
>
  <i class="check {currentClass}" />
  {#if label}
    <span>{label}</span>
  {:else}
    <slot />
  {/if}
</div>

<!-- STYLES -->
<style lang="scss">
  [data-component="Checkbox"] {
    display: flex;
    gap: 1rem;
    align-items: center;

    &.reverse {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  .check {
    font-size: 2rem;
    color: $primary;
  }
</style>
