<script lang="ts">
    import type { ISong } from "../../models";
    import { QueuedSong } from "../../models/QueuedSong";
    import UserTag from "./user-tag.svelte";

    export let song: QueuedSong | ISong;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="PlayerSongInfo">
    {#if song}
        <i class="fa-solid fa-music song-icon" />
        <span class="song-text">{song.name}</span>

        {#if song instanceof QueuedSong}
            {#if song.overrideSpecificPosition}
                <i class="fa-solid fa-arrow-up-1-9" />
            {/if}
            {#if song.isCoachRequest}
                <i class="fa-solid fa-user-group coach-request-icon" />
            {/if}
            {#if song.requestedBy}
                <UserTag username={song?.requestedBy.username} />
            {/if}
        {/if}
    {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="PlayerSongInfo"] {
        width: 100%;
        overflow: hidden;
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;

        .song-icon {
            font-size: 1.3rem;
        }
        i {
            font-size: 1.3rem;
        }

        .song-text {
            flex: auto;
            font-weight: bold;
            font-size: 1.2rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
</style>
