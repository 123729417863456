
import { generate } from 'short-uuid';
import { writable } from "svelte/store";
export enum ToastType {
    info = "info",
    danger = "danger",
    warn = "warn",
    success = "success"
}

export interface IToastInfo {
    id: string;
    title: string;
    text: string;
    duration: number;
    type: ToastType;
    datalist: string[];
    textIsHtml: boolean
}

export const toasterStore = writable<IToastInfo[]>([]);
export const showToast = async (title: string, text: string, type: ToastType, datalist: string[] = undefined, duration = 5000, textIsHtml = false) => {

    const newToast: IToastInfo = {
        id: generate(),
        title,
        text,
        duration,
        type,
        datalist,
        textIsHtml
    }
    toasterStore.update(toasts => [newToast, ...toasts])

    if (newToast.duration > 0) {
        setTimeout(() => {
            removeToast(newToast.id);
        }, newToast.duration);
    }
    return newToast.id;
}

export const removeToast = async (id: string) => {

    toasterStore.update(toasts => {
        return toasts.filter(t => t.id !== id)
    })

}