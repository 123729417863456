<script lang="ts">
    import type { SessionConnectionStatus } from "../../stores/signalr-store";
    import LoadingAnimation from "./loading-animation.svelte";

    export let status: SessionConnectionStatus;

    let mainDiv: HTMLDivElement;
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div bind:this={mainDiv} data-component="SignalrConnectionIcon">
    <i class="fa-solid fa-satellite-dish main-icon" class:shadowed={!status} />
    {#if status.connected}
        <i class="fa-solid fa-circle-check connected-icon" />
    {:else if status.hostConnection === "connecting" || status.signalrConnection === "connecting"}
        <div class="loading-animation">
            <LoadingAnimation />
        </div>
    {:else}
        <i class="fa-solid fa-circle-xmark disconnected-icon" />
    {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="SignalrConnectionIcon"] {
        width: fit-content;
        position: relative;

        .main-icon {
            &.shadowed {
                opacity: 0.3;
            }
        }

        .connected-icon {
            position: absolute;
            color: darkgreen;
            right: -0.05em;
            bottom: -0.05em;
            font-size: 0.4em;
        }

        .disconnected-icon {
            position: absolute;
            color: darkred;
            right: -0.05em;
            bottom: -0.05em;
            font-size: 0.4em;
        }

        .loading-animation {
            position: absolute;
            right: 0;
            bottom: 0;
            color: $bold;
            font-size: 0.4em;
        }
    }
</style>
