<script lang="ts">
    import { sessionDataStore } from "../stores/session-store";
    import SessionHeader from "./components/session-header.svelte";
    import GroupSession from "./group-session.svelte";
    import SingleSession from "./single-session.svelte";
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="session">
    <div class="top">
        <SessionHeader />
    </div>
    {#if $sessionDataStore.sessionType === "group"}
        <GroupSession />
    {/if}

    {#if $sessionDataStore.sessionType === "single"}
        <SingleSession />
    {/if}
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="session"] {
        .top {
            margin: 0 0.5rem 1rem 0.5rem;
        }
    }
</style>
