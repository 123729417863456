<script lang="ts">
</script>

<!--######################################################################################-->
<!--##### COMPONENT #####-->
<div data-component="AppHeader">
    <img class="logo" src="/solohub_dark.svg" alt="solohub" />
</div>

<!--######################################################################################-->

<!--##### STYLES ##### -->
<style lang="scss">
    [data-component="AppHeader"] {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        .logo {
            height: 2.5rem;
        }
    }
</style>
